<template>
  <div id="supplier">
    <!--breadcrumb route="perfil"></breadcrumb-->
    <big-title>Mi <span>información</span>.</big-title>

    <div class="vx-row">
      <div class="col-md-6">
        <h3 v-if="supplier.verified > 3">Perfil: Verificado</h3>
        <h3 v-if="supplier.verified === 3">Perfil: En Revisión</h3>
        <h3 v-if="supplier.verified < 3">Perfil: No Verificado</h3>
        <p
          v-if="supplier.verified == 0"
          style="margin-bottom:20px"
        >Tu perfil entrará a revisión para ser verificado, una vez que completes el 100% de toda tu información.</p>
      </div>
    </div>


    <div class="vx-row" v-if="!supplier.completed && !localLoading">
      <div class="vx-col w-full">
        <div class="missing-box">
          <p><strong>Tu información está casi completa, llena los siguientes campos faltantes para terminar con este apartado:</strong></p>
          <ul>
            <li v-if="missing.enterprise != ''"><strong>Empresa:</strong> {{ missing.enterprise }}</li>
            <li v-if="missing.acredit != ''"><strong>Acreditación:</strong> {{ missing.acredit }}</li>
            <li v-if="missing.contact != ''"><strong>Contacto:</strong> {{ missing.contact }}</li>
            <li v-if="missing.residence != ''"><strong>Domicilio:</strong> {{ missing.residence }}</li>
            <li v-if="missing.bank != ''"><strong>Banco:</strong> {{ missing.bank }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <profile-information :user_id="userId" @supplierUpdated="supplierUpdated"></profile-information>
      <installer-information :user_id="userId" @supplierUpdated="supplierUpdated"></installer-information>
      <contact-information :user_id="userId" @supplierUpdated="supplierUpdated"></contact-information>
      <address-information :user_id="userId" @supplierUpdated="supplierUpdated"></address-information>
      <bank-information :user_id="userId" @supplierUpdated="supplierUpdated"></bank-information>      
    </div>
  
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      localLoading: true, 
      supplier: {},
      missing: {
        enterprise: '',
        acredit: '',
        contact: '',
        residence: '',
        bank: ''
      },
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    userId: function() {
      return this.user.user_id;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.localLoading = true;
      axios.get('/api/supplier/general').then(response => {
        this.supplier = response.data;        
        this.missing = response.data.missing;
        this.localLoading = false;
      })
    },
    supplierUpdated(missing, supplier) {
      this.missing = missing;
      this.supplier = supplier;
    }
  },
    watch: {
    loading : function(newVal, oldVal){
      if(newVal == true){
         this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    }
  }
};
</script>